/* eslint-env browser, jquery */

function initLoginTabs() {
    'use strict';

    if (!jQuery.ui || !jQuery.ui.tabs) {
        return;
    }

    jQuery('.login-tabs-wrapper:not(.initLoginTabs)')
        .addClass('initLoginTabs')
        .tabs();
}

(function($) {
    'use strict';

    $(function() {
        // Create a tabbed interface on the login page.
        // =====================================================================
        initLoginTabs();
    });
})(jQuery);
